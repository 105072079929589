import React from 'react';

const Home = () => {
   return (
      <div>
         <p>Home</p>
         <ul>
            <li><a href="https://signin.aliyun.com/1268682703231466.onaliyun.com/login.htm">阿里云</a></li>
         </ul>
      </div>
   );
};

export default Home;
