import React from 'react';

const Error = () => {
   return (
      <div>
         <p>Error: Path dose not exists</p>
      </div>
   );
};

export default Error;
