import React from 'react';

const Contact = () => {
   return (
      <div>
         <p>Contact</p>
      </div>
   );
};

export default Contact;
